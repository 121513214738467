import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  writeBatch,
  query,
  where,
  and,
  orderBy,
  limit,
} from 'firebase/firestore'
import { firestore } from './firebase'

/**
 * create record under specified collection name
 * @param {string} name
 * @param {object} input
 * @param {id} string Optional document ID to create with
 * @returns
 */
const create = async (name, input, id) => {
  // collection reference
  const collectionRef = collection(firestore, name)
  const ref = id ? doc(collectionRef, id) : doc(collectionRef)

  // create record
  const docData = {
    ...input,
    id: ref.id,
    createdAt: new Date(),
    updatedAt: new Date(),
  }

  await setDoc(ref, docData)
  return docData
}

/**
 * create many records
 * @param {string} name
 * @param {array} inputs
 * @returns
 */
const createMany = (name, inputs) =>
  Promise.all(inputs.map((input) => create(name, input, input?.id)))

/**
 * find record under specified collection name
 * @param {string} name
 * @param {string} id
 * @returns
 */
const findById = (name, id) =>
  new Promise(async (resolve, reject) => {
    try {
      // get snapshot
      const snapshot = await getDoc(doc(collection(firestore, name), id))

      // return data if exists
      resolve(snapshot.exists() ? snapshot.data() : null)
    } catch (err) {
      reject(err)
    }
  })

/**
 * find records under specified collection name
 * @param {string} name
 * @param {object} whereClauses ex. { email: { operation: '==', value: "test@test.com" }, createdAt: { operation: '>', value: 12345678 } }
 * @param {object} order ex. { createdAt: 'desc', updatedAt: 'asc' }
 * @param {number} limitNum
 * @param {bool} snapshot
 * @returns
 */
const findMany = (name, whereClauses, order, limitNum, snapshot) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(firestore, name)

      const allWhereClauses = []

      // where conditions
      if (whereClauses) {
        Object.keys(whereClauses).forEach((key) => {
          const { operation, value } = whereClauses[key]
          allWhereClauses.push(where(key, operation, value))
        })
      }

      // order conditions
      const allOrderByConditions = []

      if (order) {
        Object.keys(order).forEach((key) => {
          const value = order[key]
          allOrderByConditions.push(orderBy(key, value))
        })
      }

      // limit
      const limitClause = typeof limitNum === 'number' ? limit(limitNum) : null

      let q
      if (limitClause) {
        q = query(
          collectionRef,
          and(...allWhereClauses),
          ...allOrderByConditions,
          limitClause,
        )
      } else {
        q = query(
          collectionRef,
          and(...allWhereClauses),
          ...allOrderByConditions,
        )
      }

      const snapshots = await getDocs(q)

      const documents = snapshots.docs.map((docSnapshot) => ({
        id: docSnapshot.id,
        ...docSnapshot.data(),
      }))

      resolve(snapshot ? snapshots : documents)
    } catch (err) {
      reject(err)
    }
  })

/**
 * find a record under specified collection name
 * @param {string} name
 * @param {object} whereClauses ex. { email: { operation: '==', value: "test@test.com" }, createdAt: { operation: '>', value: 12345678 } }
 * @param {object} order ex. { createdAt: 'desc', updatedAt: 'asc' }
 * @returns
 */
const findOne = async (name, whereClauses, order) => {
  try {
    const records = await findMany(name, whereClauses, order, 1)
    return new Promise((resolve) => {
      resolve(records?.[0])
    })
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

/**
 * Update a record by filter under specified collection name
 * @param {string} name
 * @param {object} whereClauses ex. { email: { operation: '==', value: "test@test.com" }, createdAt: { operation: '>', value: 12345678 } }
 * @param {object} input
 * @returns
 */
const update = async (name, whereClauses, input) => {
  try {
    const docData = await findOne(name, whereClauses)
    if (!docData) {
      return new Promise((_, reject) => {
        reject(new Error('Document not found'))
      })
    }

    // const collectionRef = collection(firestore, name)
    const docRef = doc(firestore, name, docData.id)

    // update record
    await setDoc(docRef, {
      ...input,
      updatedAt: new Date(),
    })

    // fetch id
    const record = await findById(name, doc.id)

    return new Promise((resolve) => {
      resolve(record)
    })
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

/**
 * update a record by id under specified collection name
 * @param {string} name
 * @param {string} id
 * @returns
 */
const updateById = (name, id, input) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(firestore, name, id)
      // update record
      await updateDoc(docRef, {
        ...input,
        updatedAt: new Date(),
      })

      // fetch id
      const record = await findById(name, id)

      resolve(record)
    } catch (err) {
      reject(err)
    }
  })

/**
 * update many records
 * @param {string} name
 * @param {array} inputs
 * @returns
 */
const updateMany = (name, inputs) =>
  Promise.all(inputs.map((input) => updateById(name, input?.id, input)))

/**
 * delete record under specified collection name
 * @param {string} name
 * @param {string} id
 * @returns
 */
const deleteById = (name, id) =>
  new Promise(async (resolve, reject) => {
    try {
      // update record
      const docRef = doc(firestore, name, id)
      await deleteDoc(docRef)

      resolve(true)
    } catch (err) {
      reject(err)
    }
  })

/**
 * delete records under specified collection name
 * @param {string} name
 * @param {object} whereClauses ex. { email: { operation: '==', value: "test@test.com" }, createdAt: { operation: '>', value: 12345678 } }
 * @param {object} order ex. { createdAt: 'desc', updatedAt: 'asc' }
 * @param {number} limitNum
 * @returns
 */
const deleteMany = (name, whereClauses, order, limitNum) =>
  new Promise(async (resolve, reject) => {
    try {
      const batch = writeBatch(firestore)

      const snapshots = await findMany(
        name,
        whereClauses,
        order,
        limitNum,
        true,
      )
      snapshots.forEach((snapshot) => {
        batch.delete(snapshot.ref)
      })

      // update record
      await batch.commit()

      resolve(true)
    } catch (err) {
      reject(err)
    }
  })

export default {
  create,
  createMany,
  findById,
  findMany,
  findOne,
  update,
  updateById,
  updateMany,
  deleteById,
  deleteMany,
}
