export const checkCookieEnabled = () => {
  let cookieEnabled = navigator?.cookieEnabled
  if (!cookieEnabled) {
    document.cookie = 'dentrino-cookie-test'
    cookieEnabled = document.cookie.indexOf('dentrino-cookie-test') !== -1
  }
  return cookieEnabled
}

export const encodeToBase64Async = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    console.log(
      `Trying to read file: preview: ${file.name} size: ${file.size} type: ${file.type}`,
    )

    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
