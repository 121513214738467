import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'

export const AuthenticatedRoute = () => {
  let allowAccess = true
  const location = useLocation()

  const { authState } = useAuth()
  const { loggedIn, checked } = authState

  if (!checked) {
    return null
  }

  if (!loggedIn) {
    allowAccess = false
  }

  console.log(
    `AuthenticatedRoute allow? ${allowAccess} logged in? ${loggedIn} checked? ${checked}`,
  )

  return allowAccess ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  )
}
