const colors = {
  // green
  green: '#00C7CC',
  darkGreen: '#134442',
  blackGreen: '#092927',
  lightGreen: '#80d69a',
  whiteGreen: 'rgba(39, 189, 184, .1)',
  alphaGreen: 'rgba(19, 68, 66, 0.6)',

  // yellow
  yellow: '#ffc900',
  lightYellow: '#fef5d7',
  alphaYellow: 'rgba(68, 63, 19, .6)',

  // purple
  alphaPurple: 'rgba(19, 30, 68, 0.6)',

  // cyan
  cyan: '#06fbf0',
  midCyan: '#038f89',
  darkCyan: '#024340',
  brandCyan: 'rgba(50, 177, 169, 1.0)',
  toothBoothCyan: '#7bc6d4',
  toothBoothHeroBG: '#3b3e4a',
  toothBoothHeroBG95: 'rgba(59, 62, 74, 0.95)',
  // gray
  gray: '#7f8080',
  lightGray: '#e5e5e5',
  grayWhite: '#f7f7f7',
  darkGray: '#2B373B',
  alphaDarkGray: 'rgba(43, 55, 58, .85)',

  // red
  lightRed: '#ff7d71',

  // white
  alphaWhite: 'rgba(255, 255, 255, .8)',
  alphaWhiteLighter: 'rgba(255, 255, 255, 0.6)',
}

export default colors
