import React, { useEffect } from 'react'
import loadable from '@react-loadable/revised'
import Connector from 'utils/connector'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { GENERAL_PATH, ADMIN_PATH } from 'utils/const'
import { colors } from 'theme'
import { AuthenticatedRoute } from 'Layouts/AppRouting'
import CookieConsent from 'components/CookieConsent'

const Ondemand = loadable({
  loader: () => import('./pages/Ondemand'),
  loading: () => <div />,
})

const ToothBooth = loadable({
  loader: () => import('./pages/ToothBooth'),
  loading: () => <div />,
})

const Terms = loadable({
  loader: () => import('./pages/Terms'),
  loading: () => <div />,
})

const TermsBR = loadable({
  loader: () => import('./pages/TermsBR'),
  loading: () => <div />,
})

const Privacy = loadable({
  loader: () => import('./pages/Privacy'),
  loading: () => <div />,
})

const PrivacyBR = loadable({
  loader: () => import('./pages/PrivacyBR'),
  loading: () => <div />,
})

const NotFound = loadable({
  loader: () => import('./pages/NotFound'),
  loading: () => <div />,
})

const PhotoGuide = loadable({
  loader: () => import('./pages/PhotoGuide'),
  loading: () => <div />,
})

const Dashboard = loadable({
  loader: () => import('./pages/Dashboard'),
  loading: () => <div />,
})

const Account = loadable({
  loader: () => import('./pages/Account'),
  loading: () => <div />,
})

const EnterpriseDashboard = loadable({
  loader: () => import('./pages/Enterprise'),
  loading: () => <div />,
})

const Router = () => {
  // update css variables
  useEffect(() => {
    Object.keys(colors).forEach((key) => {
      const cssKey = `--${key}`
      const cssVal = colors[key]
      document.body.style.setProperty(cssKey, cssVal)
    })
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        {/* General Routes */}
        <Route path={GENERAL_PATH.home} element={<ToothBooth />} />
        <Route path={GENERAL_PATH.terms} element={<Terms />} />
        <Route path={GENERAL_PATH.termsBR} element={<TermsBR />} />
        <Route path={GENERAL_PATH.privacy} element={<Privacy />} />
        <Route path={GENERAL_PATH.privacyBR} element={<PrivacyBR />} />
        <Route path={GENERAL_PATH.guide} element={<PhotoGuide />} />
        <Route path={GENERAL_PATH.ondemand} element={<Ondemand />} />

        {/* Authenticated Routes */}
        <Route element={<AuthenticatedRoute />}>
          <>
            <Route path={ADMIN_PATH.account} element={<Account />} />
            <Route path={ADMIN_PATH.dashboard} element={<Dashboard />} />
            <Route
              path={ADMIN_PATH.enterprise}
              element={<EnterpriseDashboard />}
            />
          </>
        </Route>

        {/* 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <CookieConsent />
    </BrowserRouter>
  )
}

export default (props) => <Connector>{() => <Router {...props} />}</Connector>
