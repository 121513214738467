import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { getStorage } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import {
  getFirestore,
  connectFirestoreEmulator,
  documentId,
} from 'firebase/firestore'

import config from './config'

const firebaseApp = initializeApp(config.firebase)
// firebaseApp.analytics()

export const auth = getAuth(firebaseApp)
export const storage = getStorage(firebaseApp)
export const functions = getFunctions(firebaseApp)
export const firestore = getFirestore(firebaseApp)
export const fieldPathDocumentId = documentId

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to activate().
// eslint-disable-next-line no-restricted-globals
if (window.location.host.startsWith('localhost:')) {
  // eslint-disable-next-line no-undef
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

export const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(config.firebase.recaptchaEnterpriseSiteKey),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
})

// eslint-disable-next-line no-restricted-globals
if (window.location.host === 'localhost') {
  let [host, port] = config.firebaseEmulator.functionsHost.split(':')
  connectFunctionsEmulator(functions, host, port)
  ;[host, port] = config.firebaseEmulator.firestoreHost.split(':')
  connectFirestoreEmulator(firestore, host, port)

  connectAuthEmulator(auth, config.firebaseEmulator.authHost)
}

export default firebaseApp
