import React, { createContext, useEffect, useState, useContext } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { onSnapshot, collection, doc } from 'firebase/firestore'
import { firestore } from '../utils/firebase'
import db from '../utils/firestore' // Adjust the import as needed

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    checked: false,
    loggedIn: false,
    me: null,
  })
  const [authStateReady, setAuthStateReady] = useState(false)

  const dispatch = useDispatch()
  let snapshotListenerUnsubscribe = null

  useEffect(() => {
    if (snapshotListenerUnsubscribe !== null) {
      console.log('Unsubscribing from previous snapshot listener...')
      snapshotListenerUnsubscribe()
      snapshotListenerUnsubscribe = null
    }

    if (!authState.checked || !authState.loggedIn || !authState.me) {
      return () => {}
    }

    // console.log(`authState: ${JSON.stringify(authState)}`)

    const userDocRef = doc(collection(firestore, 'users'), authState.me.uid)

    // Set up a listener on the user's Firestore document
    snapshotListenerUnsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data()
        const updatedMeData = {
          ...authState.me,
          ...userData,
        }

        setAuthState((prevState) => ({
          ...prevState,
          me: updatedMeData,
        }))
      } else {
        console.log('No such document!')
      }
    })

    return () => snapshotListenerUnsubscribe()
  }, [authStateReady])

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, async (me) => {
      if (!me) {
        setAuthState({ checked: true, loggedIn: false, me: null })
        setAuthStateReady(false)
      } else {
        try {
          const user = await db.findById('users', me.uid)
          const enterprise_user = await db.findOne('enterprise_customers', {
            admin_users: {
              operation: 'array-contains',
              value: me.uid,
            },
          })

          const _isEnterpriseUser = !!enterprise_user
          const _enterprise_id = enterprise_user ? enterprise_user.id : null

          const updatedMe = {
            ...me,
            ...user,
            uid: me.uid,
            emailVerified: me.emailVerified,
            isEnterpriseUser: _isEnterpriseUser,
            enterprise_id: _enterprise_id,
          }

          setAuthState((prevState) => ({
            ...prevState,
            checked: true,
            loggedIn: true,
            me: updatedMe,
          }))
          setAuthStateReady(true)
        } catch (error) {
          console.error('Failed to fetch user data', JSON.stringify(error))
        }
      }
      return () => {}
    })

    // Clean up subscriptions on unmount
    return () => unsubscribe()
  }, [dispatch])

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
