import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk'
import logger from 'redux-logger'
import app from '../modules/app.module'
import order from '../modules/order.module'
import dashboard from '../modules/dashboard.module'
import simulation from '../modules/simulation.module'

const analytics = () => (next) => (action) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: action.type,
    payload: action.payload,
  })

  console.log('Dispatching:', action)

  return next(action)
}

const store = configureStore({
  reducer: combineReducers({
    app,
    order,
    dashboard,
    simulation,
  }),
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      immutableCheck: {
        warnAfter: 128,
        immutableCheck: true, // This enables immutable state check middleware
      },
      serializableCheck: false, // Optionally disable if not needed
    }).concat(thunk, analytics)

    // Conditionally add another middleware in dev
    if (process.env.NODE_ENV !== 'production') {
      middleware.push(logger)

      const originalWarn = console.error

      console.error = (...args) => {
        if (args[0].includes('Support for defaultProps will be removed')) {
          return
        }
        originalWarn(...args)
      }
    }

    return middleware
  },
  devTools: process.env.NODE_ENV !== 'production',

  // TODO Turn off devtools in prod, or pass options in dev
  /* devTools:
    process.env.NODE_ENV === 'production'
      ? false
      : {
          stateSanitizer: stateSanitizerForDevtools
        }
        */
})

export default store

/* 
// Redux store config
const rootReducer = (initialState = {}) => {
  const reducers = combineReducers({
    app,
    order,
    dashboard,
    simulation,
  })

  // Middleware and store enhancers
  const middlewares = [
    thunk,
    process.env.NODE_ENV !== 'production' && logger,
    analytics,
  ].filter(Boolean)
  const enhancer = compose(applyMiddleware(...middlewares))
  const store = createStore(reducers, initialState, enhancer)

  return store
}

const store = rootReducer()

export default store
*/
