import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth'
import db from 'utils/firestore'

// ------------------------------------
// Constants
// ------------------------------------

const SAVE_ME = 'SAVE_ME'
const UPDATE_ME = 'UPDATE_ME'
const REQUIRE_WELCOME = 'REQUIRE_WELCOME'

const initialState = {
  checked: false,
  loggedIn: false,
  requireWelcome: false,
  userProfile: null,
}

// ------------------------------------
// Actions
// ------------------------------------

const joinWaitList =
  ({ firstName, lastName, country, email }) =>
  () =>
    new Promise(async (resolve, reject) => {
      try {
        const collectionName = 'join_wait_list'
        const emailLower = email.toLowerCase()

        // store data in firestore
        await db.create(collectionName, {
          firstName,
          lastName,
          country: country.value,
          email: emailLower,
        })
        resolve()
      } catch (err) {
        if (err.code === 'internal' || err.code === 'deadline-exceeded')
          reject(
            new Error(
              'Request timed out.  Please check your network connection and try again.',
            ),
          )
        else reject(err)
      }
    })

export const signup =
  ({ fullName, company, country, email, phone, newsLetter, password }) =>
  async (dispatch) => {
    try {
      const auth = getAuth()
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      )

      await sendEmailVerification(user)

      await db.create(
        'users',
        {
          fullName,
          company,
          country: country.value,
          email,
          phone,
          newsLetter,
          credits: 0,
        },
        user.uid,
      )

      dispatch({
        type: REQUIRE_WELCOME,
        requireWelcome: true,
      })

      return user
    } catch (err) {
      if (err.code === 'internal' || err.code === 'deadline-exceeded') {
        throw new Error(
          'Request timed out. Please check your network connection and try again.',
        )
      } else {
        throw err
      }
    }
  }

const login = (email, password) => async () =>
  new Promise(async (resolve, reject) => {
    try {
      const auth = getAuth()
      const { user } = await signInWithEmailAndPassword(auth, email, password)
      if (!user) reject(new Error('Failed to login. please try it again later'))
      resolve(user)
    } catch (err) {
      reject(err)
    }
  })

const logout = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: SAVE_ME, me: null })
      const auth = getAuth()
      await signOut(auth)
      resolve()
    } catch (err) {
      console.error('Failed to logout', err)
      reject(err)
    }
  })

const resetPassword = (email) => () =>
  new Promise(async (resolve, reject) => {
    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
      resolve()
    } catch (err) {
      reject(err)
    }
  })

const updateMe = (uid, input) => async (dispatch) => {
  try {
    const user = await db.updateById('users', uid, input)
    dispatch({ type: UPDATE_ME, user })
    return new Promise((resolve) => {
      resolve(user)
    })
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

export const actions = {
  updateMe,
  signup,
  joinWaitList,
  login,
  logout,
  resetPassword,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SAVE_ME]: (state, { userProfile }) => {
    const newState = {
      ...state,
      userProfile,
    }
    return newState
  },
  [UPDATE_ME]: (state, { user }) => {
    const newState = {
      ...state,
      userProfile: {
        ...user,
      },
    }
    return newState
  },
  [REQUIRE_WELCOME]: (state, { requireWelcome }) => {
    const newState = {
      ...state,
      requireWelcome,
    }
    return newState
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type]

  if (handler) {
    return handler(state, action)
  }

  return state
}
