import { firestore } from 'utils/firebase'
import {
  collection,
  doc,
  getDocs,
  addDoc,
  query,
  orderBy,
  updateDoc,
} from 'firebase/firestore'
import db from 'utils/firestore'

// ------------------------------------
// Constants
// ------------------------------------

const SET_ORDERS_LIST = 'SET_ORDERS_LIST'
const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER'
const TOGGLE_ORDER_DETAILS = 'TOGGLE_ORDER_DETAILS'

const initialState = {
  selectedOrder: {
    order: {},
    smile: {},
    status: {},
  },
  orders: [],
  isOpenOrderDetails: false,
}

// ------------------------------------
// Helpers
// ------------------------------------

/**
 * Generates a random string useful for the unique landing page urls,
 * avoids letters that can be confused with numbers.
 * @param {*} length
 * @returns A string of random characters
 */
function generateRandomString(length = 6) {
  const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789'
  let result = ''
  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
  }
  return result
}

/**
 *
 * @returns get all url entities from toothbooth_web_pages collection
 */
const getToothBoothCustomUrls = (id) => {
  try {
    return db.findMany(
      'toothbooth_web_pages',
      {
        enterprise_id: {
          operation: '==',
          value: id,
        },
      },
      { createdAt: 'desc' },
    )
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const getCurrentEnterpriseInfo = async (enterprise_id) => {
  try {
    const enterpriseUser = await db.findById(
      'enterprise_customers',
      enterprise_id,
    )
    if (!enterpriseUser.site_templates) return null
    return {
      template_list: enterpriseUser.site_templates,
      default_redirect_url: enterpriseUser.default_redirect_url,
    }
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const getHistory = async (id) => {
  try {
    const historyCollectionRef = collection(
      firestore,
      `toothbooth_web_pages/${id}/history`,
    )

    const q = query(historyCollectionRef, orderBy('createdAt', 'desc'))
    const querySnapshot = await getDocs(q)

    // Initialize an array to store the data of all documents
    const historyData = []

    querySnapshot.forEach((docSnapshot) => {
      // Extract the data of each document and push it to the array
      historyData.push(docSnapshot.data())
    })

    return historyData
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const addToothBoothCustomUrl = async ({
  disabled,
  description,
  display_name,
  redirect_url,
  enterprise_id,
  template_name,
  destination_email,
}) => {
  try {
    let data = {
      disabled,
      description,
      display_name,
      redirect_url,
      template_name,
      enterprise_id,
      destination_email,
    }

    const colRef = collection(firestore, 'toothbooth_web_pages')

    // Create a random 6 character string
    const token = generateRandomString()
    const custom_url = `/r/${token}`

    data = {
      ...data,
      createdAt: new Date(),
      updatedAt: new Date(),
      enable_lead_email: true,
      enable_daily_alltime_summary: false,
      enable_daily_lead_summary: false,
      attach_simulation_to_lead: true,
      custom_url,
    }

    // create record
    await addDoc(colRef, data)

    // We can't query toothbooth_web_pages for uniqueness because
    // the user doesn't have read access to the collection
    // TODO - add a check for uniqueness using FB function

    return data
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const updateToothBoothCustomUrl = (uid, data) => {
  try {
    return db.updateById('toothbooth_web_pages', uid, data)
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const deleteToothBoothCustomUrl = (id) => {
  try {
    return db.deleteById('toothbooth_web_pages', id)
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

// ------------------------------------
// Actions
// ------------------------------------

const setOrdersList = (orders) => (dispatch) =>
  dispatch({
    type: SET_ORDERS_LIST,
    orders,
  })
const setSelectedOrder = (order) => (dispatch) =>
  dispatch({
    type: SET_SELECTED_ORDER,
    order,
  })

const toggleOrderDetails = () => (dispatch) =>
  dispatch({
    type: TOGGLE_ORDER_DETAILS,
  })

const updateOrderDescription = (docId, description) => () =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(firestore, 'on_demand_orders', docId)
      // update record
      await updateDoc(docRef, { description })

      resolve()
    } catch (err) {
      reject(err)
    }
  })

export const actions = {
  setSelectedOrder,
  setOrdersList,
  toggleOrderDetails,
  getHistory,
  updateOrderDescription,
  getToothBoothCustomUrls,
  addToothBoothCustomUrl,
  updateToothBoothCustomUrl,
  deleteToothBoothCustomUrl,
  getCurrentEnterpriseInfo,
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [SET_SELECTED_ORDER]: (state, { order }) => {
    const newState = {
      ...state,
      selectedOrder: order,
    }
    return newState
  },
  [TOGGLE_ORDER_DETAILS]: (state) => ({
    ...state,
    isOpenOrderDetails: !state.isOpenOrderDetails,
  }),
  [SET_ORDERS_LIST]: (state, { orders }) => {
    const newState = {
      ...state,
      orders,
    }
    return newState
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action = {}) {
  const handler = ACTION_HANDLERS[action.type]

  if (handler) {
    return handler(state, action)
  }

  return state
}
